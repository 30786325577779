import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragDirective } from './dragDrop.directive';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileDirective } from './file.directive';
import { FileDropDirective } from './file-drop.directive';
import { CookieService } from 'ngx-cookie-service';
@NgModule({
  declarations: [
    AppComponent,
    FileDirective,
    FileDropDirective,
    DragDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
