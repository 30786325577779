import { Component } from '@angular/core';
import { FileHandle } from './dragDrop.directive';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CookieService } from 'ngx-cookie-service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class Product {
  name: string;
  price: number;
  qty: number;
  sgst: number = 0;
  cgst: number = 0;
  igst: number = 0;
  slno: string;
}
class sgstDetail {
  sgstName: string = "SGST";
  sgstamt: number = 0;
  cgstName: string = "CGST";
  cgstamt: number = 0;
}
class igstDetails {
  igstName: string = "IGST";
  igstamt: number = 0;
}
class discount {
  value: any = 0;
  percent: any = 0;
}
class Invoice {
  companyName: string;
  address: string;
  state: string;
  gstn: string;
  custname: string;
  custaddress: string;
  custstate: string;
  custgstn: string;
  invoice_no: string;
  invoice_date: string;
  due_date: string = null;
  changeLogo: any;
  products: Product[] = [];
  sgstdetails: sgstDetail[] = [];
  igstdetail: igstDetails[] = [];
  additionalDetails: string;
  newLogo: any;
  discount: discount = new discount;
  constructor() {
    this.products.push(new Product());
    this.sgstdetails.push(new sgstDetail());
    this.igstdetail.push(new igstDetails());
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private cookieService: CookieService) { }
  invoice = new Invoice();
  CompanyCookieExepDate: any = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  CustomerCookieExepDate: any = new Date(new Date().setDate(new Date().getDate() + 1));
  ngOnInit() {
    this.invoice.companyName = this.cookieService.get('CompanyName');
    this.invoice.address = this.cookieService.get('CompanyAddress');
    this.invoice.gstn = this.cookieService.get('CompanyGSTN');
    this.CompanyselectedState = this.cookieService.get('CompanyState');
    this.companystateCode = this.cookieService.get('CompanyState');
    this.invoice.state = this.states[this.companystateCode];
    this.invoice.custname = this.cookieService.get("CustomerName");
    this.invoice.custaddress = this.cookieService.get("CustomerAddress");
    this.invoice.custgstn = this.cookieService.get("CustomerGSTN");
    this.CustomerselectedState = this.cookieService.get("CustomerState");
    this.CustomerstateCode = this.cookieService.get("CustomerState");
    this.invoice.custstate = this.states[this.CustomerstateCode];
    this.invoice.invoice_no = this.cookieService.get("InvoiceNo");
    this.setIGSTorSGST(this.CustomerstateCode, this.companystateCode);
  }
  showErrorMsg: boolean = false;
  errorMessage: any;
  successMessage: any;
  changeLogo: any;
  selectedFileName: any;
  selectedFile: any;
  imagePath: any;
  logo: any = null;
  base64logo: any;
  companystateCode: any;
  companyStateName: string = '';
  CustomerstateCode: any;
  CustomerStateName: string = '';
  errorStateMessage: any;
  companyCountryCode: string = 'in';
  companyCountryName: string = 'India';
  isstateDropdownShown: boolean = true;
  isstateTextboxShown: boolean = false;
  CompanyselectedState: string = '';
  CustomerselectedState: string = '';
  DefultCountry: string = 'in';
  customerCountryCode: string = 'in';
  customerCountryName: string = 'India';
  iscustomerstateDropdownShown: boolean = true;
  iscustomerstateTextboxShown: boolean = false;
  isNotIgst: boolean = true;
  isIgst: boolean = false;
  addDiscount: boolean = false;
  isDiscountInCurrency: boolean = true;
  total_amt: number = 0;
  subtotal_amt: any = 0;
  sgst_amt: number = 0;
  cgst_amt: number = 0;
  igst_amt: number = 0;
  htmlToAdd: string = '';
  sgstName: string = "SGST";
  selectedCurrency: string = 'INR';
  iscurrencyDropdownShown: boolean = false;
  iscurrencyTextbox: boolean = true;
  currencySymbol: string = "₹";
  hiddencurvalue: string = "INR";
  gstdetails: any;
  states1 = [
    { 'id': "35", 'name': 'Andaman & Nicobar Islands' },
    { 'id': "37", 'name': 'Andhra Pradesh (New)' },
    { 'id': "28", 'name': 'Andhra Pradesh (Old)' },
    { 'id': "12", 'name': 'Arunachal Pradesh' },
    { 'id': "18", 'name': 'Assam' },
    { 'id': "10", 'name': 'Bihar' },
    { 'id': "04", 'name': 'Chandigarh' },
    { 'id': "22", 'name': 'Chhattisgarh' },
    { 'id': "26", 'name': 'Dadra & Nagar Haveli' },
    { 'id': "25", 'name': 'Daman & Diu' },
    { 'id': "07", 'name': 'Delhi' },
    { 'id': "30", 'name': 'Goa' },
    { 'id': "24", 'name': 'Gujarat' },
    { 'id': "06", 'name': 'Haryana' },
    { 'id': "02", 'name': 'Himachal Pradesh' },
    { 'id': "01", 'name': 'Jammu & Kashmir' },
    { 'id': "21", 'name': 'Jharkhand' },
    { 'id': "29", 'name': 'Karnataka' },
    { 'id': "32", 'name': 'Kerala' },
    { 'id': "31", 'name': 'Lakshadweep' },
    { 'id': "23", 'name': 'Madhya Pradesh' },
    { 'id': "27", 'name': 'Maharashtra' },
    { 'id': "14", 'name': 'Manipur' },
    { 'id': "17", 'name': 'Meghalaya' },
    { 'id': "15", 'name': 'Mizoram' },
    { 'id': "13", 'name': 'Nagaland' },
    { 'id': "20", 'name': 'Orissa' },
    { 'id': "34", 'name': 'Puducherry' },
    { 'id': "03", 'name': 'Punjab' },
    { 'id': "08", 'name': 'Rajasthan' },
    { 'id': "11", 'name': 'Sikkim' },
    { 'id': "33", 'name': 'Tamil Nadu' },
    { 'id': "36", 'name': 'Telengana' },
    { 'id': "16", 'name': 'Tripura' },
    { 'id': "05", 'name': 'Uttarakhand' },
    { 'id': "09", 'name': 'Uttar Pradesh' },
    { 'id': "19", 'name': 'West Bengal' },
  ];
  states: { [key: string]: string } = {
    '01': "Jammu & Kashmir",
    '02': "Himachal Pradesh",
    '03': "Punjab",
    '04': "Chandigarh",
    '05': "Uttarakhand",
    '06': "Haryana",
    '07': "Delhi",
    '08': "Rajasthan",
    '09': "Uttar Pradesh",
    '10': "Bihar",
    '11': "Sikkim",
    '12': "Arunachal Pradesh",
    '13': "Nagaland",
    '14': "Manipur",
    '15': "Mizoram",
    '16': "Tripura",
    '17': "Meghalaya",
    '18': "Assam",
    '19': "West Bengal",
    '21': "Orissa",
    '20': "Jharkhand",
    '22': "Chhattisgarh",
    '23': "Madhya Pradesh",
    '24': "Gujarat",
    '25': "Daman & Diu",
    '26': "Dadra & Nagar Haveli",
    '27': "Maharashtra",
    '28': "Andhra Pradesh(old)",
    '29': "Karnataka",
    '30': "Goa",
    '31': "Lakshadweep",
    '32': "Kerala",
    '33': "Tamil Nadu",
    '34': "Puducherry",
    '35': "Andaman & Nicobar Islands",
    '36': "Telengana",
    '37': "Andhra Pradesh (New)"
  };
  currencySymbols: { [key: string]: string } = {
    "AED": "AED",
    "AFN": "AFN",
    "ALL": "Lek",
    "AMD": "AMD",
    "ANG": "ƒ",
    "AOA": "AOA",
    "ARS": "$",
    "AUD": "$",
    "AWG": "ƒ",
    "AZN": "AZN",
    "BAM": "KM",
    "BBD": "$",
    "BDT": "BDT",
    "BGN": "BGN",
    "BHD": "BHD",
    "BIF": "BIF",
    "BMD": "$",
    "BND": "$",
    "BOB": "$b",
    "BOV": "BOV",
    "BRL": "R$",
    "BSD": "$",
    "BTC": "BTC",
    "BTN": "BTN",
    "BWP": "P",
    "BYR": "p.",
    "BZD": "BZ$",
    "CAD": "$",
    "CDF": "CDF",
    "CHE": "CHE",
    "CHF": "CHF",
    "CHW": "CHW",
    "CLF": "CLF",
    "CLP": "$",
    "CNY": "CNY",
    "COP": "$",
    "COU": "COU",
    "CRC": "CRC",
    "CUC": "CUC$",
    "CUP": "CUP",
    "CVE": "CVE",
    "CYP": "CYP",
    "CZK": "CZK",
    "DJF": "DJF",
    "DKK": "kr",
    "DOP": "RD$",
    "DZD": "DZD",
    "EEK": "kr",
    "EGP": "£",
    "ERN": "ERN",
    "ETB": "ETB",
    "EUR": "€",
    "FJD": "$",
    "FKP": "£",
    "GBP": "£",
    "GEL": "GEL",
    "GGP": "£",
    "GHS": "¢",
    "GIP": "£",
    "GMD": "GMD",
    "GNF": "GNF",
    "GTQ": "Q",
    "GWP": "GWP",
    "GYD": "$",
    "HKD": "元",
    "HNL": "L",
    "HRK": "kn",
    "HTG": "HTG",
    "HUF": "Ft",
    "IDR": "Rp",
    "ILS": "ILS",
    "IMP": "£",
    "INR": "₹",
    "IQD": "IQD",
    "IRR": "IRR",
    "ISK": "kr",
    "JEP": "£",
    "JMD": "J$",
    "JOD": "JOD",
    "JPY": "¥",
    "KGS": "KGS",
    "KHR": "KHR",
    "KMF": "KMF",
    "KPW": "₩",
    "KRW": "₩",
    "KYD": "$",
    "KZT": "KZT",
    "LAK": "LAK",
    "LBP": "£",
    "LKR": "Rs",
    "LRD": "$",
    "LSL": "LSL",
    "LTL": "Lt",
    "LVL": "Ls",
    "LYD": "LYD",
    "MAD": "MAD",
    "MDL": "MDL",
    "MGA": "MGA",
    "MKD": "MKD",
    "MMK": "MMK",
    "MNT": "MNT",
    "MOP": "MOP",
    "MRO": "MRO",
    "MTL": "MTL",
    "MUR": "Rp",
    "MVR": "MVR",
    "MWK": "MWK",
    "MXN": "$",
    "MXV": "MXV",
    "MYR": "RM",
    "MZN": "MT",
    "NAD": "$",
    "NGN": "NGN",
    "NIO": "C$",
    "NOK": "kr",
    "NPR": "Rp",
    "NZD": "$",
    "OMR": "OMR",
    "PAB": "B/.",
    "PEN": "S/.",
    "PGK": "PGK",
    "PHP": "Php",
    "PKR": "Rs",
    "PLN": "PLN",
    "PYG": "Gs",
    "QAR": "QAR",
    "ROL": "ROL",
    "RON": "lei",
    "RSD": "RSD",
    "RUB": "RUB",
    "RWF": "RWF",
    "SAR": "SAR",
    "SBD": "$",
    "SCR": "Rp",
    "SDD": "SDD",
    "SDG": "SDG",
    "SEK": "kr",
    "SGD": "$",
    "SHP": "£",
    "SIT": "SIT",
    "SKK": "SKK",
    "SLL": "SLL",
    "SOS": "S",
    "SRD": "$",
    "STD": "STD",
    "SVC": "$",
    "SYP": "£",
    "SZL": "SZL",
    "THB": "THB",
    "TJS": "TJS",
    "TMT": "TMT",
    "TND": "TND",
    "TOP": "TOP",
    "TRL": "TL",
    "TRY": "YTL",
    "TTD": "TT$",
    "TVD": "$",
    "TWD": "NT$",
    "TZS": "TZS",
    "UAH": "UAH",
    "UGX": "UGX",
    "USD": "$",
    "UYI": "UYI",
    "UYU": "$U",
    "UZS": "UZS",
    "VEB": "VEB",
    "VEF": "VEF",
    "VND": "VND",
    "VUV": "VUV",
    "WST": "WST",
    "XAF": "XAF",
    "XCD": "$",
    "XDR": "XDR",
    "XOF": "XOF",
    "XPF": "XPF",
    "YER": "YER",
    "ZAR": "R",
    "ZMK": "ZMK",
    "ZWD": "Z$",
  };
  countries = [
    { 'id': "us", 'name': "U.S.A" },
    { 'id': "gb", 'name': "United Kingdom" },
    { 'id': "ca", 'name': "Canada" },
    { 'id': "in", 'name': "India" },
    { 'id': "af", 'name': "Afghanistan" },
    { 'id': "ax", 'name': "Aland Islands" },
    { 'id': "al", 'name': "Albania" },
    { 'id': "dz", 'name': "Algeria" },
    { 'id': "as", 'name': "American Samoa" },
    { 'id': "ad", 'name': "Andorra" },
    { 'id': "ao", 'name': "Angola" },
    { 'id': "ai", 'name': "Anguilla" },
    { 'id': "aq", 'name': "Antarctica" },
    { 'id': "ag", 'name': "Antigua and Barbuda" },
    { 'id': "ar", 'name': "Argentina" },
    { 'id': "am", 'name': "Armenia" },
    { 'id': "aw", 'name': "Aruba" },
    { 'id': "au", 'name': "Australia" },
    { 'id': "at", 'name': "Austria" },
    { 'id': "az", 'name': "Azerbaijan" },
    { 'id': "bs", 'name': "Bahamas" },
    { 'id': "bh", 'name': "Bahrain" },
    { 'id': "bd", 'name': "Bangladesh" },
    { 'id': "bb", 'name': "Barbados" },
    { 'id': "by", 'name': "Belarus" },
    { 'id': "be", 'name': "Belgium" },
    { 'id': "bz", 'name': "Belize" },
    { 'id': "bj", 'name': "Benin" },
    { 'id': "bm", 'name': "Bermuda" },
    { 'id': "bt", 'name': "Bhutan" },
    { 'id': "bo", 'name': "Bolivia" },
    { 'id': "ba", 'name': "Bosnia" },
    { 'id': "bw", 'name': "Botswana" },
    { 'id': "bv", 'name': "Bouvet Island" },
    { 'id': "br", 'name': "Brazil" },
    { 'id': "vg", 'name': "British Virgin Islands" },
    { 'id': "bn", 'name': "Brunei" },
    { 'id': "bg", 'name': "Bulgaria" },
    { 'id': "bf", 'name': "Burkina Faso" },
    { 'id': "bi", 'name': "Burundi" },
    { 'id': "kh", 'name': "Cambodia" },
    { 'id': "cm", 'name': "Cameroon" },
    { 'id': "ca", 'name': "Canada" },
    { 'id': "cv", 'name': "Cape Verde" },
    { 'id': "ky", 'name': "Cayman Islands" },
    { 'id': "cf", 'name': "Central African Republic" },
    { 'id': "td", 'name': "Chad" },
    { 'id': "cl", 'name': "Chile" },
    { 'id': "cn", 'name': "China" },
    { 'id': "cx", 'name': "Christmas Island" },
    { 'id': "cc", 'name': "Cocos Islands" },
    { 'id': "co", 'name': "Colombia" },
    { 'id': "km", 'name': "Comoros" },
    { 'id': "cg", 'name': "Congo" },
    { 'id': "ck", 'name': "Cook Islands" },
    { 'id': "cr", 'name': "Costa Rica" },
    { 'id': "ci", 'name': "Ivory Coast" },
    { 'id': "hr", 'name': "Croatia" },
    { 'id': "cu", 'name': "Cuba" },
    { 'id': "cy", 'name': "Cyprus" },
    { 'id': "cz", 'name': "Czech Republic" },
    { 'id': "dk", 'name': "Denmark" },
    { 'id': "dj", 'name': "Djibouti" },
    { 'id': "dm", 'name': "Dominica" },
    { 'id': "do", 'name': "Dominican Republic" },
    { 'id': "ec", 'name': "Ecuador" },
    { 'id': "eg", 'name': "Egypt" },
    { 'id': "sv", 'name': "El Salvador" },
    { 'id': "gq", 'name': "Equatorial Guinea" },
    { 'id': "er", 'name': "Eritrea" },
    { 'id': "ee", 'name': "Estonia" },
    { 'id': "et", 'name': "Ethiopia" },
    { 'id': "fk", 'name': "Falkland Islands" },
    { 'id': "fo", 'name': "Faroe Islands" },
    { 'id': "fj", 'name': "Fiji" },
    { 'id': "fi", 'name': "Finland" },
    { 'id': "fr", 'name': "France" },
    { 'id': "gf", 'name': "French Guiana" },
    { 'id': "pf", 'name': "French Polynesia" },
    { 'id': "tf", 'name': "French Southern Territories" },
    { 'id': "ga", 'name': "Gabon" },
    { 'id': "gm", 'name': "Gambia" },
    { 'id': "ge", 'name': "Georgia" },
    { 'id': "de", 'name': "Germany" },
    { 'id': "gh", 'name': "Ghana" },
    { 'id': "gi", 'name': "Gibraltar" },
    { 'id': "gr", 'name': "Greece" },
    { 'id': "gl", 'name': "Greenland" },
    { 'id': "gd", 'name': "Grenada" },
    { 'id': "gp", 'name': "Guadeloupe" },
    { 'id': "gu", 'name': "Guam" },
    { 'id': "gt", 'name': "Guatemala" },
    { 'id': "gg", 'name': "Guernsey" },
    { 'id': "gw", 'name': "Guinea-Bissau" },
    { 'id': "gn", 'name': "Guinea" },
    { 'id': "gy", 'name': "Guyana" },
    { 'id': "ht", 'name': "Haiti" },
    { 'id': "hm", 'name': "Heard Island and McDonald Islands" },
    { 'id': "hn", 'name': "Honduras" },
    { 'id': "hk", 'name': "Hong Kong" },
    { 'id': "hu", 'name': "Hungary" },
    { 'id': "is", 'name': "Iceland" },
    { 'id': "in", 'name': "India" },
    { 'id': "id", 'name': "Indonesia" },
    { 'id': "ir", 'name': "Iran" },
    { 'id': "iq", 'name': "Iraq" },
    { 'id': "ie", 'name': "Ireland" },
    { 'id': "im", 'name': "Isle of Man" },
    { 'id': "il", 'name': "Israel" },
    { 'id': "it", 'name': "Italy" },
    { 'id': "jm", 'name': "Jamaica" },
    { 'id': "jp", 'name': "Japan" },
    { 'id': "je", 'name': "Jersey" },
    { 'id': "jo", 'name': "Jordan" },
    { 'id': "kz", 'name': "Kazakhstan" },
    { 'id': "ke", 'name': "Kenya" },
    { 'id': "ki", 'name': "Kiribati" },
    { 'id': "xk", 'name': "Kosova Republic" },
    { 'id': "kw", 'name': "Kuwait" },
    { 'id': "kg", 'name': "Kyrgyzstan" },
    { 'id': "la", 'name': "Laos" },
    { 'id': "lv", 'name': "Latvia" },
    { 'id': "lb", 'name': "Lebanon" },
    { 'id': "ls", 'name': "Lesotho" },
    { 'id': "lr", 'name': "Liberia" },
    { 'id': "ly", 'name': "Libya" },
    { 'id': "li", 'name': "Liechtenstein" },
    { 'id': "lt", 'name': "Lithuania" },
    { 'id': "lu", 'name': "Luxembourg" },
    { 'id': "mo", 'name': "Macau" },
    { 'id': "mk", 'name': "Macedonia" },
    { 'id': "mg", 'name': "Madagascar" },
    { 'id': "mw", 'name': "Malawi" },
    { 'id': "my", 'name': "Malaysia" },
    { 'id': "mv", 'name': "Maldives" },
    { 'id': "ml", 'name': "Mali" },
    { 'id': "mt", 'name': "Malta" },
    { 'id': "mh", 'name': "Marshall Islands" },
    { 'id': "mq", 'name': "Martinique" },
    { 'id': "mr", 'name': "Mauritania" },
    { 'id': "mu", 'name': "Mauritius" },
    { 'id': "yt", 'name': "Mayotte" },
    { 'id': "mx", 'name': "Mexico" },
    { 'id': "fm", 'name': "Micronesia" },
    { 'id': "md", 'name': "Moldova" },
    { 'id': "mc", 'name': "Monaco" },
    { 'id': "mn", 'name': "Mongolia" },
    { 'id': "me", 'name': "Montenegro" },
    { 'id': "ms", 'name': "Montserrat" },
    { 'id': "ma", 'name': "Morocco" },
    { 'id': "mz", 'name': "Mozambique" },
    { 'id': "mm", 'name': "Myanmar" },
    { 'id': "na", 'name': "Namibia" },
    { 'id': "nr", 'name': "Nauru" },
    { 'id': "np", 'name': "Nepal" },
    { 'id': "nl", 'name': "Netherlands" },
    { 'id': "an", 'name': "Netherlands Antilles" },
    { 'id': "nc", 'name': "New Caledonia" },
    { 'id': "nz", 'name': "New Zealand" },
    { 'id': "ni", 'name': "Nicaragua" },
    { 'id': "ne", 'name': "Niger" },
    { 'id': "ng", 'name': "Nigeria" },
    { 'id': "nu", 'name': "Niue" },
    { 'id': "nf", 'name': "Norfolk Island" },
    { 'id': "mp", 'name': "Northern Mariana Islands" },
    { 'id': "kp", 'name': "North Korea" },
    { 'id': "no", 'name': "Norway" },
    { 'id': "om", 'name': "Oman" },
    { 'id': "pk", 'name': "Pakistan" },
    { 'id': "pw", 'name': "Palau" },
    { 'id': "ps", 'name': "Palestine" },
    { 'id': "pa", 'name': "Panama" },
    { 'id': "pg", 'name': "Papua New Guinea" },
    { 'id': "py", 'name': "Paraguay" },
    { 'id': "pe", 'name': "Peru" },
    { 'id': "ph", 'name': "Philippines" },
    { 'id': "pn", 'name': "Pitcairn" },
    { 'id': "pl", 'name': "Poland" },
    { 'id': "pt", 'name': "Portugal" },
    { 'id': "pr", 'name': "Puerto Rico" },
    { 'id': "qa", 'name': "Qatar" },
    { 'id': "ro", 'name': "Romania" },
    { 'id': "ru", 'name': "Russia" },
    { 'id': "rw", 'name': "Rwanda" },
    { 'id': "kn", 'name': "Saint Kitts and Nevis" },
    { 'id': "lc", 'name': "Saint Lucia" },
    { 'id': "pm", 'name': "Saint Pierre and Miquelon" },
    { 'id': "vc", 'name': "Saint Vincent and the Grenadines" },
    { 'id': "ws", 'name': "Samoa" },
    { 'id': "sm", 'name': "SanMarino" },
    { 'id': "sa", 'name': "Saudi Arabia" },
    { 'id': "sn", 'name': "Senegal" },
    { 'id': "rs", 'name': "Serbia" },
    { 'id': "sc", 'name': "Seychelles" },
    { 'id': "sl", 'name': "Sierra Leone" },
    { 'id': "sg", 'name': "Singapore" },
    { 'id': "sk", 'name': "Slovakia" },
    { 'id': "si", 'name': "Slovenia" },
    { 'id': "sb", 'name': "Solomon Islands" },
    { 'id': "so", 'name': "Somalia" },
    { 'id': "za", 'name': "South Africa" },
    { 'id': "gs", 'name': "South Georgia and the South Sandwich Islands" },
    { 'id': "kr", 'name': "South Korea" },
    { 'id': "es", 'name': "Spain" },
    { 'id': "lk", 'name': "Sri Lanka" },
    { 'id': "sd", 'name': "Sudan" },
    { 'id': "sr", 'name': "Suriname" },
    { 'id': "sj", 'name': "Svalbard and Jan Mayen" },
    { 'id': "sz", 'name': "Swaziland" },
    { 'id': "se", 'name': "Sweden" },
    { 'id': "ch", 'name': "Switzerland" },
    { 'id': "sy", 'name': "Syria" },
    { 'id': "tw", 'name': "Taiwan" },
    { 'id': "tj", 'name': "Tajikistan" },
    { 'id': "tz", 'name': "Tanzania" },
    { 'id': "th", 'name': "Thailand" },
    { 'id': "tg", 'name': "Togo" },
    { 'id': "tk", 'name': "Tokelau" },
    { 'id': "to", 'name': "Tonga" },
    { 'id': "tt", 'name': "Trinidad and Tobago" },
    { 'id': "tn", 'name': "Tunisia" },
    { 'id': "tr", 'name': "Turkey" },
    { 'id': "tm", 'name': "Turkmenistan" },
    { 'id': "tv", 'name': "Tuvalu" },
    { 'id': "ug", 'name': "Uganda" },
    { 'id': "ua", 'name': "Ukraine" },
    { 'id': "ae", 'name': "United Arab Emirates" },
    { 'id': "gb", 'name': "United Kingdom" },
    { 'id': "uy", 'name': "Uruguay" },
    { 'id': "us", 'name': "U.S.A" },
    { 'id': "uz", 'name': "Uzbekistan" },
    { 'id': "vu", 'name': "Vanuatu" },
    { 'id': "va", 'name': "Vatican City" },
    { 'id': "ve", 'name': "Venezuela" },
    { 'id': "vn", 'name': "Vietnam" },
    { 'id': "vg", 'name': "Virgin Islands, British" },
    { 'id': "vi", 'name': "Virgin Islands, U.S." },
    { 'id': "wf", 'name': "Wallis and Futuna" },
    { 'id': "eh", 'name': "Western Sahara" },
    { 'id': "ye", 'name': "Yemen" },
    { 'id': "zm", 'name': "Zambia" },
    { 'id': "zw", 'name': "Zimbabwe" }
  ];
  countryCurrrency = [
    { 'id': "AED", 'name': "AED - UAE Dirham (AED)" },
    { 'id': "AFN", 'name': "AFN - Afghani (AFN)" },
    { 'id': "ALL", 'name': "ALL - Lek (Lek)" },
    { 'id': "AMD", 'name': "AMD - Armenian Dram (AMD)" },
    { 'id': "ANG", 'name': "ANG - Netherlands Antillian Guilder (ƒ)" },
    { 'id': "AOA", 'name': "AOA - Kwanza (AOA)" },
    { 'id': "ARS", 'name': "ARS - Argentine Peso ($)" },
    { 'id': "AUD", 'name': "AUD - Australian Dollar ($)" },
    { 'id': "AWG", 'name': "AWG - Aruban Guilder (ƒ)" },
    { 'id': "AZN", 'name': "AZN - Azerbaijanian Manat (AZN)" },
    { 'id': "BAM", 'name': "BAM - Convertible Marks (KM)" },
    { 'id': "BBD", 'name': "BBD - Barbados Dollar ($)" },
    { 'id': "BDT", 'name': "BDT - Taka (BDT)" },
    { 'id': "BGN", 'name': "BGN - Bulgarian Lev (BGN)" },
    { 'id': "BHD", 'name': "BHD - Bahraini Dinar (BHD)" },
    { 'id': "BIF", 'name': "BIF - Burundi Franc (BIF)" },
    { 'id': "BMD", 'name': "BMD - Bermudian Dollar (Bermuda Dollar) ($)" },
    { 'id': "BND", 'name': "BND - Brunei Dollar ($)" },
    { 'id': "BOB", 'name': "BOB - Boliviano ($b)" },
    { 'id': "BOV", 'name': "BOV - Mvdol (BOV)" },
    { 'id': "BRL", 'name': "BRL - Brazilian Real (R$)" },
    { 'id': "BSD", 'name': "BSD - Bahamian Dollar ($)" },
    { 'id': "BTC", 'name': "BTC - Bitcoin (BTC)" },
    { 'id': "BTN", 'name': "BTN - Ngultrum (BTN)" },
    { 'id': "BWP", 'name': "BWP - Pula (P)" },
    { 'id': "BYR", 'name': "BYR - Belarussian Ruble (p.)" },
    { 'id': "BZD", 'name': "BZD - Belize Dollar (BZ$)" },
    { 'id': "CAD", 'name': "CAD - Canadian Dollar ($)" },
    { 'id': "CDF", 'name': "CDF - Franc Congolais (CDF)" },
    { 'id': "CHE", 'name': "CHE - WIR Euro (CHE)" },
    { 'id': "CHF", 'name': "CHF - Swiss Franc (CHF)" },
    { 'id': "CHW", 'name': "CHW - WIR Franc (CHW)" },
    { 'id': "CLF", 'name': "CLF - Unidades de formento (CLF)" },
    { 'id': "CLP", 'name': "CLP - Chilean Peso ($)" },
    { 'id': "CNY", 'name': "CNY - Yuan Renminbi (CNY)" },
    { 'id': "COP", 'name': "COP - Colombian Peso ($)" },
    { 'id': "COU", 'name': "COU - Unidad de Valor Real (COU)" },
    { 'id': "CRC", 'name': "CRC - Costa Rican Colon (CRC)" },
    { 'id': "CUC", 'name': "CUC - Cuba Convertible Peso (CUC$)" },
    { 'id': "CUP", 'name': "CUP - Cuban Peso (CUP)" },
    { 'id': "CVE", 'name': "CVE - Cape Verde Escudo (CVE)" },
    { 'id': "CYP", 'name': "CYP - Cyprus Pound (CYP)" },
    { 'id': "CZK", 'name': "CZK - Czech Koruna (CZK)" },
    { 'id': "DJF", 'name': "DJF - Djibouti Franc (DJF)" },
    { 'id': "DKK", 'name': "DKK - Danish Krone (kr)" },
    { 'id': "DOP", 'name': "DOP - Dominican Peso (RD$)" },
    { 'id': "DZD", 'name': "DZD - Algerian Dinar (DZD)" },
    { 'id': "EEK", 'name': "EEK - Kroon (kr)" },
    { 'id': "EGP", 'name': "EGP - Egyptian Pound (£)" },
    { 'id': "ERN", 'name': "ERN - Nakfa (ERN)" },
    { 'id': "ETB", 'name': "ETB - Ethiopian Birr (ETB)" },
    { 'id': "EUR", 'name': "EUR - Euro (€)" },
    { 'id': "FJD", 'name': "FJD - Fiji Dollar ($)" },
    { 'id': "FKP", 'name': "FKP - Falkland Islands Pound (£)" },
    { 'id': "GBP", 'name': "GBP - Pound Sterling (£)" },
    { 'id': "GEL", 'name': "GEL - Lari (GEL)" },
    { 'id': "GGP", 'name': "GGP - Guernsey Pound (£)" },
    { 'id': "GHS", 'name': "GHS - Cedi (¢)" },
    { 'id': "GIP", 'name': "GIP - Gibraltar Pound (£)" },
    { 'id': "GMD", 'name': "GMD - Dalasi (GMD)" },
    { 'id': "GNF", 'name': "GNF - Guinea Franc (GNF)" },
    { 'id': "GTQ", 'name': "GTQ - Quetzal (Q)" },
    { 'id': "GWP", 'name': "GWP - Guinea-Bissau Peso (GWP)" },
    { 'id': "GYD", 'name': "GYD - Guyana Dollar ($)" },
    { 'id': "HKD", 'name': "HKD - Hong Kong Dollar (元)" },
    { 'id': "HNL", 'name': "HNL - Lempira (L)" },
    { 'id': "HRK", 'name': "HRK - Croatian Kuna (kn)" },
    { 'id': "HTG", 'name': "HTG - Gourde (HTG)" },
    { 'id': "HUF", 'name': "HUF - Forint (Ft)" },
    { 'id': "IDR", 'name': "IDR - Rupiah (Rp)" },
    { 'id': "ILS", 'name': "ILS - New Israeli Sheqel (ILS)" },
    { 'id': "IMP", 'name': "IMP - Isle of Man Pound (£)" },
    { 'id': "INR", 'name': "INR - Indian Rupee (Rs.)" },
    { 'id': "IQD", 'name': "IQD - Iraqi Dinar (IQD)" },
    { 'id': "IRR", 'name': "IRR - Iranian Rial (IRR)" },
    { 'id': "ISK", 'name': "ISK - Iceland Krona (kr)" },
    { 'id': "JEP", 'name': "JEP - Jersey Pound (£)" },
    { 'id': "JMD", 'name': "JMD - Jamaican Dollar (J$)" },
    { 'id': "JOD", 'name': "JOD - Jordanian Dinar (JOD)" },
    { 'id': "JPY", 'name': "JPY - Yen (¥)" },
    { 'id': "KES", 'name': "KES - Kenyan Shilling (KES)" },
    { 'id': "KGS", 'name': "KGS - Som (KGS)" },
    { 'id': "KHR", 'name': "KHR - Riel (KHR)" },
    { 'id': "KMF", 'name': "KMF - Comoro Franc (KMF)" },
    { 'id': "KPW", 'name': "KPW - North Korean Won (₩)" },
    { 'id': "KRW", 'name': "KRW - Won (₩)" },
    { 'id': "KWD", 'name': "KWD - Kuwaiti Dinar (KWD)" },
    { 'id': "KYD", 'name': "KYD - Cayman Islands Dollar ($)" },
    { 'id': "KZT", 'name': "KZT - Tenge (KZT)" },
    { 'id': "LAK", 'name': "LAK - Kip (LAK)" },
    { 'id': "LBP", 'name': "LBP - Lebanese Pound (£)" },
    { 'id': "LKR", 'name': "LKR - Sri Lanka Rupee (Rs)" },
    { 'id': "LRD", 'name': "LRD - Liberian Dollar ($)" },
    { 'id': "LSL", 'name': "LSL - Loti (LSL)" },
    { 'id': "LTL", 'name': "LTL - Lithuanian Litas (Lt)" },
    { 'id': "LVL", 'name': "LVL - Latvian Lats (Ls)" },
    { 'id': "LYD", 'name': "LYD - Libyan Dinar (LYD)" },
    { 'id': "MAD", 'name': "MAD - Moroccan Dirham (MAD)" },
    { 'id': "MDL", 'name': "MDL - Moldovan Leu (MDL)" },
    { 'id': "MGA", 'name': "MGA - Malagascy Ariary (MGA)" },
    { 'id': "MKD", 'name': "MKD - Denar (MKD)" },
    { 'id': "MMK", 'name': "MMK - Kyat (MMK)" },
    { 'id': "MNT", 'name': "MNT - Tugrik (MNT)" },
    { 'id': "MOP", 'name': "MOP - Pataca (MOP)" },
    { 'id': "MRO", 'name': "MRO - Ouguiya (MRO)" },
    { 'id': "MTL", 'name': "MTL - Maltese Lira (MTL)" },
    { 'id': "MUR", 'name': "MUR - Mauritius Rupee (Rp)" },
    { 'id': "MVR", 'name': "MVR - Rufiyaa (MVR)" },
    { 'id': "MWK", 'name': "MWK - Kwacha (MWK)" },
    { 'id': "MXN", 'name': "MXN - Mexican Peso ($)" },
    { 'id': "MXV", 'name': "MXV - Mexican Unidad de Inversion (UID) (MXV)" },
    { 'id': "MYR", 'name': "MYR - Malaysian Ringgit (RM)" },
    { 'id': "MZN", 'name': "MZN - Metical (MT)" },
    { 'id': "NAD", 'name': "NAD - Namibian Dollar ($)" },
    { 'id': "NGN", 'name': "NGN - Naira (NGN)" },
    { 'id': "NIO", 'name': "NIO - Cordoba Oro (C$)" },
    { 'id': "NOK", 'name': "NOK - Norwegian Krone (kr)" },
    { 'id': "NPR", 'name': "NPR - Nepalese Rupee (Rp)" },
    { 'id': "NZD", 'name': "NZD - New Zealand Dollar ($)" },
    { 'id': "OMR", 'name': "OMR - Rial Omani (OMR)" },
    { 'id': "PAB", 'name': "PAB - Balboa (B/.)" },
    { 'id': "PEN", 'name': "PEN - Nuevo Sol (S/.)" },
    { 'id': "PGK", 'name': "PGK - Kina (PGK)" },
    { 'id': "PHP", 'name': "PHP - Philippine Peso (Php)" },
    { 'id': "PKR", 'name': "PKR - Pakistan Rupee (Rs)" },
    { 'id': "PLN", 'name': "PLN - Zloty (PLN)" },
    { 'id': "PYG", 'name': "PYG - Guarani (Gs)" },
    { 'id': "QAR", 'name': "QAR - Qatari Rial (QAR)" },
    { 'id': "ROL", 'name': "ROL - Old Leu (ROL)" },
    { 'id': "RON", 'name': "RON - New Leu (lei)" },
    { 'id': "RSD", 'name': "RSD - Serbian Dinar (RSD)" },
    { 'id': "RUB", 'name': "RUB - Russian Ruble (RUB)" },
    { 'id': "RWF", 'name': "RWF - Rwanda Franc (RWF)" },
    { 'id': "SAR", 'name': "SAR - Saudi Riyal (SAR)" },
    { 'id': "SBD", 'name': "SBD - Solomon Islands Dollar ($)" },
    { 'id': "SCR", 'name': "SCR - Seychelles Rupee (Rp)" },
    { 'id': "SDD", 'name': "SDD - Sudanese Dinar (SDD)" },
    { 'id': "SDG", 'name': "SDG - Sudanese Pound (SDG)" },
    { 'id': "SEK", 'name': "SEK - Swedish Krona (kr)" },
    { 'id': "SGD", 'name': "SGD - Singapore Dollar ($)" },
    { 'id': "SHP", 'name': "SHP - Saint Helena Pound (£)" },
    { 'id': "SIT", 'name': "SIT - Tolar (SIT)" },
    { 'id': "SKK", 'name': "SKK - Slovak Koruna (SKK)" },
    { 'id': "SLL", 'name': "SLL - Leone (SLL)" },
    { 'id': "SOS", 'name': "SOS - Somali Shilling (S)" },
    { 'id': "SRD", 'name': "SRD - Surinam Dollar ($)" },
    { 'id': "STD", 'name': "STD - Dobra (STD)" },
    { 'id': "SVC", 'name': "SVC - El Salvador Colon ($)" },
    { 'id': "SYP", 'name': "SYP - Syrian Pound (£)" },
    { 'id': "SZL", 'name': "SZL - Lilangeni (SZL)" },
    { 'id': "THB", 'name': "THB - Baht (THB)" },
    { 'id': "TJS", 'name': "TJS - Somoni (TJS)" },
    { 'id': "TMT", 'name': "TMT - Manat (TMT)" },
    { 'id': "TND", 'name': "TND - Tunisian Dinar (TND)" },
    { 'id': "TOP", 'name': "TOP - Paanga (TOP)" },
    { 'id': "TRL", 'name': "TRL - Turkish Lira (TL)" },
    { 'id': "TRY", 'name': "TRY - New Turkish Lira (YTL)" },
    { 'id': "TTD", 'name': "TTD - Trinidad and Tobago Dollar (TT$)" },
    { 'id': "TVD", 'name': "TVD - Tuvalu Dollar ($)" },
    { 'id': "TWD", 'name': "TWD - New Taiwan Dollar (NT$)" },
    { 'id': "TZS", 'name': "TZS - Tanzanian Shilling (TZS)" },
    { 'id': "UAH", 'name': "UAH - Hryvnia (UAH)" },
    { 'id': "UGX", 'name': "UGX - Uganda Shilling (UGX)" },
    { 'id': "USD", 'name': "USD - US Dollar ($)" },
    { 'id': "UYI", 'name': "UYI - Uruguay Peso en Unidades Indexadas (UYI)" },
    { 'id': "UYU", 'name': "UYU - Peso Uruguayo ($U)" },
    { 'id': "UZS", 'name': "UZS - Uzbekistan Sum (UZS)" },
    { 'id': "VEB", 'name': "VEB - Bolivar (VEB)" },
    { 'id': "VEF", 'name': "VEF - Bolivar Fuerte (VEF)" },
    { 'id': "VND", 'name': "VND - Dong (VND)" },
    { 'id': "VUV", 'name': "VUV - Vatu (VUV)" },
    { 'id': "WST", 'name': "WST - Tala (WST)" },
    { 'id': "XAF", 'name': "XAF - CFA Franc BEAC (XAF)" },
    { 'id': "XCD", 'name': "XCD - East Caribbean Dollar ($)" },
    { 'id': "XDR", 'name': "XDR - SDR (XDR)" },
    { 'id': "XOF", 'name': "XOF - CFA Franc BCEAO (XOF)" },
    { 'id': "XPF", 'name': "XPF - CFP Franc (XPF)" },
    { 'id': "YER", 'name': "YER - Yemeni Rial (YER)" },
    { 'id': "ZAR", 'name': "ZAR - Rand (R)" },
    { 'id': "ZMK", 'name': "ZMK - Kwacha (ZMK)" },
    { 'id': "ZWD", 'name': "ZWD - Zimbabwe Dollar (Z$)" }
  ];

  AddressInput(event) {
    if(event.target.id === "address") {
      if(!this.invoice.address) {
        if (/ /.test(event.key)) {
          event.preventDefault();
        }
      }
    } else if(event.target.id === "custaddress") {
      if(!this.invoice.custaddress) {
        if (/ /.test(event.key)) {
          event.preventDefault();
        }
      }
    }
  }

  addCompanyState(event: any) {
    this.errorStateMessage = [];

    if (event) {
      event.preventDefault();
      this.companystateCode = event.target.value.substring(0, 2);
      this.cookieService.set('CompanyState', this.companystateCode, this.CompanyCookieExepDate, '/');
      if (this.companystateCode in this.states) {
        this.companyStateName = this.states[this.companystateCode];
        this.CompanyselectedState = this.companystateCode;
        this.invoice.state = this.states[this.companystateCode];

        this.setIGSTorSGST(this.CustomerstateCode, this.companystateCode);
      }
      else {
        this.companyStateName = '';
        this.errorStateMessage.push('State Not Found');
        return;
      }
    }
    this.emptycgstsgst();
    this.calculateTotalAmut();
  }

  addCustomerState(event: any) {
    this.errorStateMessage = [];
    if (event) {
      event.preventDefault();
      this.CustomerstateCode = event.target.value.substring(0, 2);
      this.cookieService.set('CustomerState', this.CustomerstateCode, this.CustomerCookieExepDate, '/');
      if (this.CustomerstateCode in this.states) {
        this.CustomerStateName = this.states[this.CustomerstateCode];
        this.CustomerselectedState = this.CustomerstateCode;
        this.invoice.custstate = this.states[this.CustomerstateCode];

        this.setIGSTorSGST(this.CustomerstateCode, this.companystateCode);
      }
      else {
        this.CustomerStateName = '';
        this.errorStateMessage.push('Sate Not Found');
        return;
      }
    }
    this.emptycgstsgst();
    this.calculateTotalAmut();
  }

  addProduct() {
    let amt = this.invoice.products.map(p => ([p.slno, p.name, p.price, p.qty, (p.price * p.qty).toFixed(2)]));
    this.total_amt = +this.invoice.products.reduce((sum, p) => sum + ((p.qty || 0) * (p.price || 0)), 0).toFixed(2);
    this.total_amt = this.total_amt ? this.total_amt : 0;
    this.invoice.products.push(new Product());
  }
  
  calculateDiscountEntry() {
    if(this.isDiscountInCurrency) {
      this.invoice.discount.percent = ((this.invoice.discount.value/this.total_amt)*100).toFixed(2);
    } else {
      this.invoice.discount.value = ((this.invoice.discount.percent / 100)*this.total_amt).toFixed(2);
    }
  }

  cookieCompanyName() {
    this.cookieService.set('CompanyName', this.invoice.companyName, this.CompanyCookieExepDate, '/');
  }

  cookieCompanyAddress() {
    this.cookieService.set('CompanyAddress', this.invoice.address, this.CompanyCookieExepDate, '/');
  }

  cookieCompanyGSTN() {
    this.cookieService.set('CompanyGSTN', this.invoice.gstn, this.CompanyCookieExepDate, '/');
  }

  cookieCustomerName() {
    this.cookieService.set('CustomerName', this.invoice.custname, this.CustomerCookieExepDate, '/');
  }

  cookieCustomerAddress() {
    this.cookieService.set('CustomerAddress', this.invoice.custaddress, this.CustomerCookieExepDate, '/');
  }

  cookieCustomerGSTN() {
    this.cookieService.set('CustomerGSTN', this.invoice.custgstn, this.CustomerCookieExepDate, '/');
  }

  cookieInvoiceNo() {
    this.cookieService.set('InvoiceNo', this.invoice.invoice_no, this.CustomerCookieExepDate, '/');
  }

  companyCountrySelect(event: any) {
    event.preventDefault();
    this.companyCountryCode = event.target.value;
    this.companyCountryName = event.target.options[event.target.options.selectedIndex].text;
    if (this.companyCountryCode != "in") {
      this.isstateTextboxShown = true;
      this.isstateDropdownShown = false;
    }
    else {
      this.isstateTextboxShown = false;
      this.isstateDropdownShown = true;
    }
  }

  customerCountrySelect(event: any) {
    event.preventDefault();
    this.customerCountryCode = event.target.value;
    this.customerCountryName = event.target.options[event.target.options.selectedIndex].text;
    if (this.customerCountryCode != "in") {
      this.iscustomerstateTextboxShown = true;
      this.iscustomerstateDropdownShown = false;
    }
    else {
      this.iscustomerstateTextboxShown = false;
      this.iscustomerstateDropdownShown = true;
    }
  }

  calculateprize(i) {
    const array = this.invoice.products;
    const deduct_amt = array[i].price * array[i].qty || 0;
    const sgstArray = this.invoice.sgstdetails;
    let sgst = array[i].sgst;
    if (!sgstArray[i]) {
      this.invoice.sgstdetails.push(new sgstDetail());
    }
    if (sgst) {
      let sgstname = sgstArray[i].sgstName = "SGST(" + ('0' + sgst).slice(-2) + "%)";
      let sgst_tax = (deduct_amt / 100) * sgst;
      let sgstTaxValue = sgstArray[i].sgstamt = sgst_tax;
    }
    let cgst = array[i].cgst;
    if (cgst) {
      let cgstname = sgstArray[i].cgstName = "CGST(" + ('0' + cgst).slice(-2) + "%)";
      let cgst_tax = (deduct_amt / 100) * cgst;
      let cgstTaxValue = sgstArray[i].cgstamt = cgst_tax;
      const sgstArrayremvoe = this.invoice.sgstdetails;
    }
    const igstArray = this.invoice.igstdetail;
    let igst = array[i].igst;
    if (!igstArray[i]) {
      this.invoice.igstdetail.push(new igstDetails());
    }
    if (igst) {
      let igstname = igstArray[i].igstName = "IGST(" + ('0' + igst).slice(-2) + "%)";
      let igst_tax = (deduct_amt / 100) * igst;
      let igstTaxValue = igstArray[i].igstamt = igst_tax;
    }

    this.calculateTotalAmut();
  }

  calculateTotalAmut() {
    const array = this.invoice.products;
    const sgstArray = this.invoice.sgstdetails;
    const igstArray = this.invoice.igstdetail;
    array.map(function (key, index) {
      if (key.qty > 0 && key.price > 0) {
        const deduct_amt = array[index].price * array[index].qty;
        let sgst = array[index].sgst;
        if (sgst) {
          let sgstname = sgstArray[index].sgstName = "SGST(" + ('0' + sgst).slice(-2) + "%)";
          let sgst_tax = (deduct_amt / 100) * sgst;
          let sgstTaxValue = sgstArray[index].sgstamt = sgst_tax;
        }
        let cgst = array[index].cgst;
        if (cgst) {
          let cgstname = sgstArray[index].cgstName = "CGST(" + ('0' + cgst).slice(-2) + "%)";
          let cgst_tax = (deduct_amt / 100) * cgst;
          let cgstTaxValue = sgstArray[index].cgstamt = cgst_tax;
        }
        let igst = array[index].igst;
        if (igst) {
          let igstname = igstArray[index].igstName = "IGST(" + ('0' + igst).slice(-2) + "%)";
          let igst_tax = (deduct_amt / 100) * igst;
          let igstTaxValue = igstArray[index].igstamt = igst_tax;
        }
      }
    });
    let amt = this.invoice.products.map(p => ([p.name, p.price, p.qty, (p.price * p.qty).toFixed(2), p.sgst, p.cgst, p.igst]));
    this.subtotal_amt = +this.invoice.products.reduce((sum, p) => sum + ((p.qty || 0) * (p.price || 0)), 0).toFixed(2);
    if (this.isNotIgst) {
      this.total_amt = +this.invoice.products.reduce((sum, p) => sum + ((p.qty || 0) * (p.price || 0)) + ((((p.qty || 0) * (p.price || 0)) / 100) * p.sgst) + ((((p.qty || 0) * (p.price || 0)) / 100) * p.cgst), 0).toFixed(2);
      this.sgst_amt = +this.invoice.sgstdetails.reduce((sum, p) => sum + p.sgstamt, 0).toFixed(2);
      this.cgst_amt = +this.invoice.sgstdetails.reduce((sum, p) => sum + p.cgstamt, 0).toFixed(2);
    }
    else {
      this.total_amt = +this.invoice.products.reduce((sum, p) => sum + ((p.qty || 0) * (p.price || 0)) + ((((p.qty || 0) * (p.price || 0)) / 100) * p.igst), 0).toFixed(2);
      this.igst_amt = +this.invoice.igstdetail.reduce((sum, p) => sum + p.igstamt, 0).toFixed(2);
    }
    if (this.total_amt) {
      this.calculateDiscountEntry();
      this.total_amt = this.total_amt;
      this.total_amt -= this.invoice.discount.value || 0;
    }
    else {
      this.total_amt = 0;
    }
    if (this.subtotal_amt) {
      this.subtotal_amt = this.subtotal_amt;
    }
    else {
      this.subtotal_amt = 0;
    }
  }

  setIGSTorSGST(customerState, companyState) {
    if (customerState == companyState) {
      this.invoice.products.map(function (p) {
        p.sgst = 0;
        p.cgst = 0;
      })
      this.isNotIgst = true;
      this.isIgst = false;
    }
    else {
      this.invoice.products.map(function (p) {
        p.igst = 0;
      })

      this.isIgst = true;
      this.isNotIgst = false;
    }
  }

  GSTINInput(event) {
    if(!/[a-z]|[A-Z]|[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  InvoiveNoInput(event) {
    if(this.invoice.invoice_no) {
      if (!/[a-z]|[A-Z]|[0-9]| /.test(event.key)) {
        event.preventDefault();
      }
    } else {
      if (!/[a-z]|[A-Z]|[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  }

  NameInput(event) {
    if(event.target.id === "name") {
      if(this.invoice.companyName) {
        if (!/[a-z]|[A-Z]| /.test(event.key)) {
          event.preventDefault();
        }
      } else {
        if (!/[a-z]|[A-Z]/.test(event.key)) {
          event.preventDefault();
        }
      }
    } else if(event.target.id === "custname") {
      if(this.invoice.custname) {
        if (!/[a-z]|[A-Z]| /.test(event.key)) {
          event.preventDefault();
        }
      } else {
        if (!/[a-z]|[A-Z]/.test(event.key)) {
          event.preventDefault();
        }
      }
    }
  }

  ProductNameInput(event, index) {
    if(this.invoice.products[index].name) {
      if(!/[a-z]|[A-Z]|[0-9]| /.test(event.key)) {
        event.preventDefault();
      }
    } else {
      if(!/[a-z]|[A-Z]|[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  }

  ProductQuantityInput(event) {
    if(!/[0-9]|\./.test(event.key)) {
      event.preventDefault();
    }
  }

  ProductPriceInput(event) {
    if(!/[0-9]|\./.test(event.key)) {
      event.preventDefault();
    }
  }
  emptycgstsgst() {
    if (this.isNotIgst) {
      const sgstArray = this.invoice.sgstdetails;
      sgstArray.map(function (key, index) {
        let sgstname = sgstArray[index].sgstName = "SGST";
        let sgstTaxValue = sgstArray[index].sgstamt = 0;
        let cgstname = sgstArray[index].cgstName = "CGST";
        let cgstTaxValue = sgstArray[index].cgstamt = 0;
      });
    }
    else {
      const igstArray = this.invoice.igstdetail;
      igstArray.map(function (key, index) {
        let igstname = igstArray[index].igstName = "IGST";
        let igstTaxValue = igstArray[index].igstamt = 0;
      });
    }
  }
  calculateSgst(i) {
    const array = this.invoice.products;
    const deduct_amt = array[i].price * array[i].qty || 0;
    if (this.isNotIgst) {

      this.invoice.sgstdetails.push(new sgstDetail());
      const sgstArray = this.invoice.sgstdetails;
      let sgst = array[i].sgst;
      let sgstname = sgstArray[i].sgstName = "SGST(" + ('0' + sgst).slice(-2) + "%)";
      let sgst_tax = (deduct_amt / 100) * sgst;
      let sgstTaxValue = sgstArray[i].sgstamt = sgst_tax;

      let cgst = array[i].cgst;
      let cgstname = sgstArray[i].cgstName = "CGST(" + ('0' + cgst).slice(-2) + "%)";
      let cgst_tax = (deduct_amt / 100) * cgst;
      let cgstTaxValue = sgstArray[i].cgstamt = cgst_tax;
      const sgstArrayremvoe = this.invoice.sgstdetails;
      this.invoice.sgstdetails.map(function (key, index) {
        if (key.sgstamt == 0 && key.cgstamt == 0) {
          sgstArrayremvoe.splice(index, 1);
        }
      })
      this.calculateTotalAmut();

    }
    else {

      this.invoice.igstdetail.push(new igstDetails());
      const igstArray = this.invoice.igstdetail;
      let igst = array[i].igst;
      let igstname = igstArray[i].igstName = "IGST(" + ('0' + igst).slice(-2) + "%)";
      let igst_tax = (deduct_amt / 100) * igst;
      let igstTaxValue = igstArray[i].igstamt = igst_tax;
      const igstArrayremvoe = this.invoice.igstdetail;
      this.invoice.igstdetail.map(function (key, index) {
        if (key.igstamt == 0) {
          igstArrayremvoe.splice(index, 1);
        }
      })
      this.calculateTotalAmut();

    }

  }
  showCurrencyDropdown(event: any) {
    this.iscurrencyTextbox = false;
    this.iscurrencyDropdownShown = true;

  }
  showCurrencySelect(event: any) {

    this.iscurrencyTextbox = true;
    this.iscurrencyDropdownShown = false;
    if (event.target.value in this.currencySymbols) {
      this.currencySymbol = this.currencySymbols[event.target.value];
      this.hiddencurvalue = event.target.value;
    }

  }
  onFileChanged(event: any, type: any) {
    console.log('file', event);
    if (event) {
      this.errorMessage = [];
      this.successMessage = '';
      if (type == 'select') {
        this.changeLogo = event.target.files[0];
      } else {
        this.changeLogo = event[0].file;
      }
      this.selectedFileName = this.changeLogo.name;
      var mimeType = this.changeLogo.type;
      if (mimeType.match(/image\/*/) == null) {
        this.errorMessage.push('Image not supported');
        return;
      }
      this.selectedFile = this.changeLogo.name;
      var reader = new FileReader();
      this.imagePath = event;
      reader.readAsDataURL(this.changeLogo);
      reader.onload = (_event) => {
        this.logo = reader.result;
      }
    }
  }
  generatePDF(action = 'open') {
    var gstn = {};
    var custgstn = {};
    var notes = [];
    var invoice_date = new Date(this.invoice.invoice_date);
    var due_date = [];
    var discount = [{}, {}];
    if (this.invoice.due_date) {
      var invoice_due_date = new Date(this.invoice.due_date);

      due_date = [
        { text: `Due Date`, style : ['sectionHeader']},
        { text: `${this.getDoubleDigitNumber(invoice_due_date.getDate())}-${this.getDoubleDigitNumber(invoice_due_date.getMonth()+1)}-${invoice_due_date.getFullYear()}`, alignment: 'right' },
      ]
    }
    if (this.invoice.additionalDetails) {
      var note = [{
        text: 'Notes:',
        style: ['sectionHeader', 'headerMargin'],
      },
      {
        text: this.invoice.additionalDetails,
        lineHeight: 1.3,
        color: '#6d6d6d',
        margin: [0, 0, 0, 15]
      },]
      notes.push(note);
    }
    if (this.invoice.gstn)
      gstn = { text: 'GSTN ' + this.invoice.gstn };
    if (this.invoice.custgstn)
      custgstn = { text: 'GSTN ' + this.invoice.custgstn };
    if(this.invoice.discount.value !== 0)
      discount = [{ text: 'Discount', style: ['subaligncont', 'sectionHeader'] }, { text: '- ₹' + `${this.invoice.discount.value}`, alignment: "right", style: 'subaligncont' }];
    var array1 = [];
    this.invoice.products.forEach(function (p) {
      var x1 = [
        { text: `${p.name}`, style: 'margincont', alignment: "left" },
        { text: `${p.qty}`, style: 'margincont' },
        { text: `${p.price}`, style: 'margincont' },
        { text: `${p.cgst}`, style: 'margincont' },
        { text: `${p.sgst}`, style: 'margincont' },
        { text: `${(p.price * p.qty)}`, style: 'margincont', alignment: "right" },
      ]
      array1.push(x1);
    })
    var array2 = [];
    this.invoice.products.forEach(function (p) {
      var x2 = [
        { text: `${p.name}`, style: 'margincont', alignment: "left" },
        { text: `${p.qty}`, style: 'margincont' },
        { text: `${p.price}`, style: 'margincont' },
        { text: `${p.igst}`, style: 'margincont' },
        { text: `${(p.price * p.qty)}`, style: 'margincont', alignment: "right" },
      ]
      array2.push(x2);
    })

    if (this.invoice.products.length) {
      this.showErrorMsg = false;
      let logo: any;
      if (this.logo == null) {
        logo = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8NDRUPDw8VFRUVFRUVFRUVFRUVFRUVFRUWFhUVFRUYHSggGBolHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NFQ0NFSsZFR0rKy0tKysrKystKy0tLSsrNy03KystLSsrKy0tKzctKy0tKy0rLS0rKy0rLS0rKysrK//AABEIAMQBAQMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAJRABAAIBAgUEAwAAAAAAAAAAAAHwETGhIUGRsdECUWHhcYHB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAwDAQACEQMRAD8A7gAgl/KiACgCXVUugHRS8kuoF4gAAAF9wFLoX3LqAACAKAhdVBLqqXQAvAFAAAAAx8icAFAAAAAAABFAAABFARQAvJFAS2FAAABLot0AAAAAAAAAMgKACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH7AAAAAAAABQAAAAAARQAAAAAAAAAAAAABAAAAAAUAAAAAAEUAQBUAAAAABRAUQBQAAAAAABAAAMgAAAAoAAACCoCoACoAAIAAACgAACgAAAAAAAAACAAAAoAAAAACKIAoAgqIAqAqKiioogigoAAAAAAACGQwFAAUAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQFRAEAAAAAAAAUAAAAAlAAAAUAAAAAAABAAAADAAAAAAAAoAAAAAACIKJkBRDIKIAoCgAAAAAIAAAAomFBBAFEBQAAEBRAFBAATKCiEAsjOVBRMqCiKoCAKIoCoAogIogCDWYBUAAELdgVC28gAJJkAygC5EMgAZBJORMHqQCJI1MAoQYUFymQFEAUIIvUALehbsCiW3mtt5AAAvEZ4e4It2S7AKs634QAL3L2QEX7RAVS9vIACALKABBOoAHqACVQBZEAWDwAI15QBYu5F2AQi7F2+wFW/wu+ABrACo/9k=';
      }
      else {
        logo = this.logo;
      }
      var commonDetails = [
        {
          columns: [
            [
              {
                image: logo,
                alignment: 'left',
                fit: [240, 40]
              }
            ],
            [
              {
                text: 'INVOICE',
                fontSize: 36,
                bold: true,
                alignment: 'right',
                color: '#111'
              }
            ]
          ],
          margin : [0, 0, 0, 50]
        },
        {
          columns: [
            [
              { text: "Company Details", style : ['sectionHeader', 'headerMargin']},
              { text: this.invoice.companyName, style: ['marginBottom'] },
              { text: this.invoice.address, style: ['marginBottom'] },
              { text: this.invoice.state, style: ['marginBottom'] },
              { text: this.companyCountryName, style: ['marginBottom'] },
              gstn
            ],
            [
              { text: "Bill To", style : ['sectionHeader', 'headerMargin']},
              { text: this.invoice.custname, style: ['marginBottom'] },
              { text: this.invoice.custaddress, style: ['marginBottom'] },
              { text: this.invoice.custstate, style: ['marginBottom'] },
              { text: this.customerCountryName, style: ['marginBottom'] },
              custgstn
            ],
            [
              [{
                columns: [
                  { text: `Invoice No`, style : ['sectionHeader'] },
                  { text: `${this.invoice.invoice_no}`, alignment: 'right' },
                ],
                style: ['marginBottom']
              }],
              [{
                columns: [
                  { text: `Invoice Date`, style : ['sectionHeader'] },
                  { text: `${this.getDoubleDigitNumber(invoice_date.getDate())}-${this.getDoubleDigitNumber(invoice_date.getMonth()+1)}-${invoice_date.getFullYear()}`, alignment: 'right' },
                ],
                style: ['marginBottom']
              }],
              [{
                columns: due_date,
              }]
            ]
          ],
          margin : [0, 0, 0, 50]
        },
        { text: 'Order Details', style : ['sectionHeader', 'headerMargin'] },
      ];
      var docDefinition: any;
      if (this.isNotIgst) {

        docDefinition = {
          footer: {
            // text: 'Generated by freeonlineinvoicegenerator.com',
            text: ['Generated by ', { text: 'freeonlineinvoicegenerator.com', link: 'https://freeonlineinvoicegenerator.com/', decoration: 'underline' }],
            alignment: 'center',
            fontSize: 10,
            bold: true,
            color: '#6d6d6d',
          },
          content: [
            ...commonDetails,
            {
              layout: 'noBorders',
              table: {
                headerRows: 1,
                widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto',],
                body: [
                  [
                    { text: 'Service/Product', style: ['tableHeader'], alignment: "left" },
                    { text: 'Qty', style: ['tableHeader'] },
                    { text: 'Rate', style: ['tableHeader'] },
                    { text: 'CGST(%)', style: ['tableHeader'] },
                    { text: 'SGST(%)', style: ['tableHeader'] },
                    { text: 'Amount', style: ['tableHeader'] },
                  ],
                  ...array1,
                ],
                lineColor: 'red'
              },
              margin: [0, 0, 0, 30],
            },
            {
              columns: [
                { width: '*', text: '' },
                { width: '*', text: '' },
                {
                  width: 150,
                  layout: 'noBorders',
                  style: '',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [{ text: 'Sub Total', style: ['subaligncont', 'sectionHeader'] }, { text: '₹' + `${this.subtotal_amt}`, alignment: "right", style: 'subaligncont' }],
                      [{ text: 'CGST', style: ['subaligncont', 'sectionHeader'] }, { text: '₹' + `${this.cgst_amt}`, alignment: "right", style: 'subaligncont' }],
                      [{ text: 'SGST', style: ['subaligncont', 'sectionHeader'] }, { text: '₹' + `${this.sgst_amt}`, alignment: "right", style: 'subaligncont' }],
                      discount,
                      [{ text: 'Total', style: ['subaligncont', 'totalbgcolor'] }, { text: '₹' + `${this.total_amt}`, alignment: "right", style: ['totalbgcolor', 'subaligncont'] }],
                    ],
                    alignment: 'right',
                  }
                }
              ],
              margin: [0, 0, 0, 50],
            },
            ...notes
          ],
        };
      }
      else {
        docDefinition =
        {
          content: [
            ...commonDetails,
            {
              layout: 'noBorders',
              table: {
                headerRows: 1,
                widths: ['*', 'auto', 'auto', 'auto', 'auto',],
                body: [
                  [
                    { text: 'Service/Product', style: ['tableHeader'], alignment: "left" },
                    { text: 'Qty', style: ['tableHeader'] },
                    { text: 'Rate', style: ['tableHeader'] },
                    { text: 'IGST(%)', style: ['tableHeader'] },
                    { text: 'Amount', style: ['tableHeader'] },
                  ],
                  ...array2,
                ],
                lineColor: '#777'
              },
              margin: [0, 0, 0, 30],
            },
            {
              columns: [
                { width: '*', text: '' },
                { width: '*', text: '' },
                {
                  width: 150,
                  layout: 'noBorders',
                  style: '',
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [{ text: 'Sub Total', style: ['subaligncont', 'sectionHeader'] }, { text: '₹' + `${this.subtotal_amt}`, alignment: "right", style: 'subaligncont' }],
                      [{ text: 'IGST', style: ['subaligncont', 'sectionHeader'] }, { text: '₹' + `${this.igst_amt}`, alignment: "right", style: 'subaligncont' }],
                      discount,
                      [{ text: 'Total', style: ['subaligncont', 'totalbgcolor'] }, { text: '₹' + `${this.total_amt}`, alignment: "right", style: ['totalbgcolor', 'subaligncont'] }],
                    ],
                    alignment: 'right',
                  }
                }
              ],
              margin: [0, 0, 0, 50],
            },
            ...notes
          ],
        };
      }
    }
    else {
      alert('Please add at least one product');
      return;
    }
    var doc = {
      ...docDefinition, styles: {
        sectionHeader: {
          bold: true,
          color: "#787878"
        },
        headerMargin: {
          margin: [0, 0, 0, 10]
        },
        marginBottom: {
          margin: [0, 0, 0, 4]
        },
        tableHeader:
        {
          fillColor: '#2c2c2c',
          color: 'white',
          margin: [10, 5, 10, 5],
          alignment: "center"
        },
        totalbgcolor:
        {
          fillColor: '#2c2c2c',
          color: 'white',
        },
        margincont: {
          margin: [10, 10, 10, 0],
          alignment: "center"
        },
        subaligncont: {
          margin: [10, 2]
        },
      },
      defaultStyle: {
        fontSize: 10,
      }
    };
    if (action === 'download') {
      pdfMake.createPdf(doc).download();
    } else if (action === 'print') {
      pdfMake.createPdf(doc).print();
    } else {
      pdfMake.createPdf(doc).open();
    }
  }

  getDoubleDigitNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  removeProduct(i) {
    if (this.invoice.products.length > 1) {
      const array = this.invoice.products;
      const sgst = this.invoice.sgstdetails;
      const igst = this.invoice.igstdetail;
      const index = array.indexOf(i);
      if (i > -1) { array.splice(i, 1); }
      if (this.isIgst) {
        if (i > -1) { igst.splice(i, 1); }
      }
      else {
        if (i > -1) { sgst.splice(i, 1); }
      }

      this.calculateTotalAmut();
    } else {
      this.invoice.products.pop();
      this.invoice.products.push(new Product());
      this.invoice.sgstdetails.pop();
      this.invoice.sgstdetails.push(new sgstDetail());
      this.invoice.igstdetail.pop();
      this.invoice.igstdetail.push(new igstDetails());
      this.calculateTotalAmut();
      this.calculateprize(0);
    }
  }
  name = 'Angular 5';
  files: FileHandle[] = [];

  filesDropped(files: FileHandle[]): void {
    this.files = files;
    for (let file of this.files) {
      console.log('file', file.url);
      this.logo = file.url;
    }
  }
  showError() {
    this.showErrorMsg = true;
    const form = document.getElementById("invoiceform");
    form.scrollIntoView({ behavior: "smooth" });
  }

  resetDiscount() {
    this.invoice.discount = new discount;

    this.calculateTotalAmut();
  }
}