<nav class="cod-bg-blue-nav p-4 text-center">
  <a class="navbar-brand" href="#"><!-- INVOICE GENERATOR -->
    <img src="/assets/images/free-online-invoice-generator-logo.png" alt="Free online invoice generator logo">
  </a>
  <div class="container p-0 p-sm-3 text-light fig_header">
    <h1 class="fig_heading mt-4">
      Create Your Invoice for
      <span class="pl-3 pl-md-4 position-relative">
        free! <img src="../assets/images/yellow-curve-line.svg" alt="ycline" class="position-absolute"/>
      </span>
    </h1>
    <p class="fig_subheader mx-auto mb-4">
      Our search engine marketing will provide you with the winning mantra of improving customer reach.
    </p>
    <div class="fig_step_container d-flex justify-content-center mx-auto mb-5">
      <div class="d-flex align-items-start align-items-md-center flex-column flex-md-row">
        <div class="mr-2 text-left">
          <img src="../assets/images/right_arrow.svg" alt=">>" class="mr-2 d-md-none"/>
          <span>Fill in the details</span>
        </div>
        <div class="d-flex mr-2 text-left">
          <img src="../assets/images/right_arrow.svg" alt=">>" class="mr-2"/>
          <span>Preview, download or print your invoice</span>
        </div>
        <div class="text-left">
          <img src="../assets/images/right_arrow.svg" alt=">>" class="mr-2"/>
          <span>Tada-it's done!</span>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="invoice-contentsecwraper">
  <div class="container invoice-contentsecwraped">
    <form class="form-wrapper" #invoiceForm="ngForm" id="invoiceform">
      <div class="mb-4 invoiceContainerFluid form-wrapped">
        <div class="row px-3 px-md-0 form-wrapped-inner">
          <div class="col-md-12 pt-5 invoiceContainer">
            <div class="row flex-column-reverse flex-md-row mb-4 position-relative">
              <div class="col-12 col-md-6">
                <div class="position-relative logo_input_container">
                  <div class="logo_container ml-auto mr-auto ml-md-0 d-flex justify-content-center align-items-start py-3"
                    appDrag (files)="onFileChanged($event,'drag')" (click)="fileInput.click()"
                    *ngIf="!logo" style="cursor: pointer;"
                  >
                    <img src="../assets/images/cloud_upload.svg" alt="cu_img" class="mr-2"/>
                    <div>
                      <p class="mb-1 logo_add_text">Add Logo (Optional)</p>
                      <p class="mb-0 logo_type_text">
                        JPG, JPEG,PNG, 240 x 240 pixels <br>@ 72 DPI, Maximum size of 1MB
                      </p>
                    </div>
                  </div>
                  <div appDrag (files)="onFileChanged($event,'drag')" style="background:#fff!important">
                    <img class="imagePreview" [src]="logo" *ngIf="logo" (click)="fileInput.click()"
                      style="max-height: 100px;">
                  </div>
                  <input class="form-control" type="file" style="display: none;" accept=".jpg, .jpeg, .png"
                    (change)="onFileChanged($event,'select')" id="updateLogo" #fileInput name="newLogo"
                    [ngClass]="{'isInvalid': invoiceForm.submitted && newLogo.invalid}"
                    [(ngModel)]="invoice.newLogo" #newLogo="ngModel">
                </div>
              </div>
              <div class="col-12 d-md-none d-lg-block mb-4 text-center fig_formerror_container" *ngIf="showErrorMsg">
                <span class="error d-inline-block text-light">Please fill required fields</span>
              </div>
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <h1 class="tiHeading text-center text-md-right">INVOICE</h1>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 d-none d-md-block d-lg-none text-center" *ngIf="showErrorMsg">
                <span class="error d-inline-block text-light">Please fill required fields</span>
              </div>
            </div>
            <div class="row flex-md-row d-none d-lg-flex">
              <div class="col-12 col-md-7">
                <div class="row">
                  <div class="col-6">
                    <h4 class="mb-3 fig_color_blue">Your Company Details</h4>
                  </div>
                  <div class="col-6">
                    <h4 class="mb-3 fig_color_blue">Bill To</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="row flex-column-reverse flex-lg-row">
              <div class="col-12 col-lg-7">
                <div class="row">
                  <div class="col-12 col-md-6 mb-3">
                    <h4 class="mb-3 d-lg-none fig_color_blue">Your Company Details</h4>
                    <div class="form-group">
                      <input type="text" class="form-control" name="name" id="name" placeholder='Name'
                        [ngClass]="{'isInvalid': invoiceForm.submitted && CompanyName.invalid}"
                        [(ngModel)]="invoice.companyName" #CompanyName="ngModel" required
                        (keypress)="NameInput($event)" (change)="cookieCompanyName()">
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" name="address" id="address" row="3" placeholder='Address'
                        [ngClass]="{'isInvalid': invoiceForm.submitted && Address.invalid}"
                        [(ngModel)]="invoice.address" #Address="ngModel" required
                        (keypress)="AddressInput($event)" (change)="cookieCompanyAddress()"></textarea>
                    </div>
                    <div class="form-group" *ngIf="isstateDropdownShown">
                      <select class="form-control select-appearance" name="state" id="state" [(ngModel)]="CompanyselectedState"
                        (change)="addCompanyState($event)">
                        <option value="">----Select State---</option>
                        <option *ngFor="let state of states1; let i=index" value="{{state.id}}">{{state.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control select-appearance" tabindex="4" name="country" id="companyCountrySelect"
                        [(ngModel)]="companyCountryCode" (change)="companyCountrySelect($event)">
                        <option *ngFor="let country of countries; let i=index" value="{{country.id}}"
                          [attr.data-compname]="country.name" disabled="disabled"
                        >
                          {{country.name}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input type="text" onKeyPress="if(this.value.length==16) return false;" class="form-control"
                        name="gstn" id="gstn" placeholder="GSTIN" maxlength="15" (change)="addCompanyState($event)"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && gstn.invalid}" [(ngModel)]="invoice.gstn"
                        (keypress)="GSTINInput($event)" #gstn="ngModel" (change)="cookieCompanyGSTN()" maxlength="15">
                    </div>
                    <div class="form-group" *ngIf="isstateTextboxShown">
                      {{errorStateMessage}}
                      <input type="text" class="form-control" name="state" id="state" placeholder="State"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && State.invalid}" [(ngModel)]="invoice.state"
                        #State="ngModel" required value="{{companyStateName}}">
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <h4 class="mb-3 d-lg-none fig_color_blue">Bill To</h4>
                    <div class="form-group">
                      <input type="text" class="form-control" name="custname" id="custname" placeholder="Name"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && CustName.invalid}"
                        [(ngModel)]="invoice.custname" #CustName="ngModel" required
                        (keypress)="NameInput($event)" (change)="cookieCustomerName()">
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" name="custaddress" id="custaddress" row="3" placeholder="Address"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && custaddress.invalid}"
                        [(ngModel)]="invoice.custaddress" #custaddress="ngModel" required
                        (keypress)="AddressInput($event)" (change)="cookieCustomerAddress()">
                      </textarea>
                    </div>
                    <div class="form-group" *ngIf="iscustomerstateDropdownShown">
                      <select class="form-control select-appearance" name="custstate" id="custstate" [(ngModel)]="CustomerselectedState"
                        (change)="addCustomerState($event)">
                        <option value="">----Select State---</option>
                        <option *ngFor="let state of states1; let i=index" value="{{state.id}}">{{state.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control select-appearance" tabindex="4" name="country" id="customerCountrySelect"
                        [(ngModel)]="customerCountryCode" (change)="customerCountrySelect($event)">
                        <option *ngFor="let country of countries; let i=index" value="{{country.id}}"
                          [attr.data-custname]="country.name" disabled="disabled"
                        >
                          {{country.name}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" name="custgstn" id="custgstn" placeholder="GSTIN"
                        (change)="addCustomerState($event)"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && custgstn.invalid}"
                        [(ngModel)]="invoice.custgstn" #custgstn="ngModel"
                        (keypress)="GSTINInput($event)" (change)="cookieCustomerGSTN()">
                    </div>
                    <div class="form-group" *ngIf="iscustomerstateTextboxShown">
                      {{errorStateMessage}}
                      <input type="text" class="form-control" name="custstate" id="custstate" placeholder="State"
                        [ngClass]="{'isInvalid': invoiceForm.submitted && custstate.invalid}"
                        [(ngModel)]="invoice.custstate" #custstate="ngModel" value="{{CustomerStateName}}" required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 mb-3">
                <div class="row">
                  <div class="col-12 billRightSection">
                    <div class="row">
                      <div class="col-lg-3">
                        <label>Invoice No</label>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group">
                          <input type="text" class="form-control" name="invoice_no" id="invoice_no"
                            placeholder="Invoice No" (keypress)="InvoiveNoInput($event)"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && invoice_no.invalid}"
                            [(ngModel)]="invoice.invoice_no" #invoice_no="ngModel" required
                            (input)="cookieInvoiceNo()">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3">
                        <label for="invoice_date">Invoice Date</label>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group">
                          <input type="Date" class="form-control" name="invoice_date" id="invoice_date"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && invoice_date.invalid}"
                            [(ngModel)]="invoice.invoice_date" #invoice_date="ngModel" required>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3">
                        <label for="due_date">Due Date</label>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group">
                          <input type="date" class="form-control" name="due_date" id="due_date"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && due_date.invalid}"
                            [(ngModel)]="invoice.due_date" #due_date="ngModel">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-12 py-3">
                <h4 class="mb-3 fig_color_blue">Order Details</h4>
                <div class="d-none d-lg-block">
                  <table class="table text-center fig_order_table">
                    <thead>
                      <tr class="text-light fig_order_table_header">
                        <th class="col-auto text-left">Item</th>
                        <th class="col-1">Qty</th>
                        <th class="col-1">Rate</th>
                        <th class="col-1" *ngIf="isNotIgst">CGST(%)</th>
                        <th class="col-1" *ngIf="isNotIgst">SGST(%)</th>
                        <th class="col-1" *ngIf="isIgst">IGST(%)</th>
                        <th class="col-2">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="fig_order_items">
                      <tr *ngFor="let product of invoice.products; let i=index" class="position-relative fig_order_item">
                        <td scope="row">
                          <input type="text" class="form-control" name="productName{{i}}"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && ProductName.invalid}" id="productName"
                            [(ngModel)]="product.name" #ProductName="ngModel" required placeholder="Name of Service/Product"
                            (keypress)="ProductNameInput($event,i)">
                        </td>
                        <td>
                          <input type="number" class="form-control" name="quantity{{i}}" id="quantity"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && ProductQty.invalid}"
                            (change)="calculateTotalAmut()" [(ngModel)]="product.qty" #ProductQty="ngModel" required
                            placeholder="00" (keypress)="ProductQuantityInput($event)">
                        </td>
                        <td>
                          <input type="number" class="form-control" name="price{{i}}" id="price"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && ProductPrice.invalid}"
                            (change)="calculateprize(i)" [(ngModel)]="product.price" #ProductPrice="ngModel" required
                            placeholder="00" (keypress)="ProductPriceInput($event)">
                        </td>
                        <td scope="row" *ngIf="isNotIgst">
                          <input type="number" class="form-control" name="cgst{{i}}" id="cgst" (change)="calculateSgst(i)"
                            [(ngModel)]="product.cgst" placeholder="00" (keypress)="ProductPriceInput($event)">
                        </td>
                        <td scope="row" *ngIf="isNotIgst">
                          <input type="number" class="form-control" name="sgst{{i}}" id="sgst" (change)="calculateSgst(i)"
                            [(ngModel)]="product.sgst" #sgst="ngModel" placeholder="00" (keypress)="ProductPriceInput($event)">
                        </td>
                        <td scope="row" *ngIf="isIgst">
                          <input type="number" class="form-control" name="igst{{i}}" id="igst" (change)="calculateSgst(i)"
                            [(ngModel)]="product.igst" #igst="ngModel" placeholder="00" (keypress)="ProductPriceInput($event)">
                        </td>
                        <td>
                          <input type="text" class="form-control" value="{{product.price * product.qty || ''}}" readonly
                            placeholder="00" style="background:#fff;">
                        </td>
                        <div class="position-absolute end-0 fig_item_del" (click)="removeProduct(i)">
                          <img src="../assets/images/cross.svg" alt="x" />
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mb-2 d-lg-none">
                  <div class="py-2" *ngFor="let product of invoice.products; let i=index" >
                    <div class="row">
                      <div class="col-6">
                        <h5 class="fig_color_blue">Item {{ i+1 }}</h5>
                      </div>
                      <div class="col-6 text-right">
                        <div class="d-inline-flex p-2" style="cursor: pointer;" (click)="removeProduct(i)">
                          <img src="../assets/images/cross.svg" alt="x" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label class="form-label">Item</label>
                          <input type="text" class="form-control" name="productName{{i}}"
                            [ngClass]="{'isInvalid': invoiceForm.submitted && ProductName.invalid}" id="productName"
                            [(ngModel)]="product.name" #ProductName="ngModel" required placeholder="Name of Service/Product"
                            (keypress)="ProductNameInput($event,i)">
                        </div>
                      </div>
                      <div class="col-12 d-flex flex-column flex-sm-row" style="gap: 10px;">
                        <div class="d-flex">
                          <div class="form-group col-6 pl-0 pr-1 mb-0">
                            <label class="form-label">Qty</label>
                            <input type="number" class="form-control" name="quantity{{i}}" id="quantity"
                              [ngClass]="{'isInvalid': invoiceForm.submitted && ProductQty.invalid}"
                              (change)="calculateTotalAmut()" [(ngModel)]="product.qty" #ProductQty="ngModel" required
                              placeholder="00" (keypress)="ProductQuantityInput($event)">
                          </div>
                          <div class="form-group col-6 pr-0 pl-1 mb-0">
                            <label class="form-label">Rate</label>
                            <input type="number" class="form-control" name="price{{i}}" id="price"
                              [ngClass]="{'isInvalid': invoiceForm.submitted && ProductPrice.invalid}"
                              (change)="calculateprize(i)" [(ngModel)]="product.price" #ProductPrice="ngModel" required
                              placeholder="00" (keypress)="ProductPriceInput($event)">
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="form-group col-6 pl-0 pr-1 mb-0" *ngIf="isNotIgst">
                            <label class="form-label">CGST(%)</label>
                            <input type="number" class="form-control" name="cgst{{i}}" id="cgst" (change)="calculateSgst(i)"
                              [(ngModel)]="product.cgst" placeholder="00" (keypress)="ProductPriceInput($event)">
                          </div>
                          <div class="form-group col-6 pr-0 pl-1 mb-0" *ngIf="isNotIgst">
                            <label class="form-label">SGST(%)</label>
                            <input type="number" class="form-control" name="sgst{{i}}" id="sgst" (change)="calculateSgst(i)"
                              [(ngModel)]="product.sgst" #sgst="ngModel" placeholder="00" (keypress)="ProductPriceInput($event)">
                          </div>
                          <div class="form-group col-12 px-0 mb-0" *ngIf="isIgst">
                            <label class="form-label">IGST(%)</label>
                            <input type="number" class="form-control" name="igst{{i}}" id="igst" (change)="calculateSgst(i)"
                              [(ngModel)]="product.igst" #igst="ngModel" placeholder="00" (keypress)="ProductPriceInput($event)">
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group col-12 p-0 mb-0">
                            <label class="form-label">Amount</label>
                            <input type="text" class="form-control" value="{{product.price * product.qty || ''}}" readonly
                              placeholder="00" style="background:#fff;">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addRow mb-3">
                  <button type="button" (click)="addProduct()" class="fig_bg_yellow">
                    <span>+</span>&nbsp;&nbsp;Add Item
                  </button>
                </div>
              </div>
            </div>
            <div class="row align-items-end justify-content-between flex-column-reverse flex-md-row mb-3 mb-md-5">
              <div class="col-md-7 mb-3">
                <h6 class="card-title">Notes</h6>
                <div class="form-group m-0">
                  <textarea class="form-control" name="additionalDetails" rows="2"
                    [(ngModel)]="invoice.additionalDetails"></textarea>
                </div>
              </div>
              <div class="col-md-5 pl-md-5 mb-3 totalSection">
                <div class="col-12 d-flex pt-2 pl-0 pl-sm-3">
                  <div class="col-6 col-md-7">SubTotal</div>
                  <div class="col-6 col-md-5 text-right">{{ currencySymbol }}{{ subtotal_amt }}</div>
                </div>
                <div *ngIf="isNotIgst">
                  <div class="col-12 d-flex pt-2 pl-0 pl-sm-3">
                    <div class="col-6 col-md-7">SGST</div>
                    <div class="col-6 col-md-5 text-right">{{ currencySymbol }}{{ sgst_amt }}</div>
                  </div>
                  <div class="col-12 d-flex pt-2 pl-0 pl-sm-3">
                    <div class="col-6 col-md-7">CGST</div>
                    <div class="col-6 col-md-5 text-right">{{ currencySymbol }}{{ cgst_amt }}</div>
                  </div>
                </div>
                <div *ngIf="isIgst">
                  <div class="col-12 d-flex pt-2 pl-0 pl-sm-3">
                    <div class="col-6 col-md-7">IGST</div>
                    <div class="col-6 col-md-5 text-right">{{ currencySymbol }}{{ igst_amt }}</div>
                  </div>
                </div>
                <div class="col-12 py-2 pl-0 pl-sm-3 text-right discount_container">
                  <span class="px-2 mx-2 discount_add_btn" *ngIf="!addDiscount" (click)="addDiscount = true">
                    <span>+</span> Discount
                  </span>
                  <div class="col-12 p-0 d-flex align-items-center position-relative" *ngIf="addDiscount">
                    <div class="col-6 text-left">
                      Discount(<span *ngIf="isDiscountInCurrency">{{currencySymbol}}</span>
                        <span *ngIf="!isDiscountInCurrency">%</span>)
                    </div>
                    <div class="col-6 pr-0 d-flex justify-content-end">
                      <div class="d-flex align-items-center pl-2 mr-2 discount_input">
                        <span style="cursor: pointer;" (click)="isDiscountInCurrency = !isDiscountInCurrency">
                          <img src="../assets/images/exchange.svg" alt="->"/>
                        </span>
                        <input type="number" class="form-control text-right px-2" placeholder="00" id="discount"
                          name="discount" [(ngModel)]="invoice.discount.value" (keypress)="ProductPriceInput($event)"
                          (change)="calculateTotalAmut()" *ngIf="isDiscountInCurrency"/>
                        <input type="number" class="form-control text-right px-2" placeholder="00" id="discount"
                          name="discount" [(ngModel)]="invoice.discount.percent" (keypress)="ProductPriceInput($event)"
                          (change)="calculateTotalAmut()" *ngIf="!isDiscountInCurrency"/>
                      </div>
                    </div>
                    <div class="position-absolute discount_del_btn" (click)="addDiscount = false; resetDiscount()">
                      <img src="../assets/images/cross.svg" alt="x" />
                    </div>
                  </div>
                </div>
                <div class="col-12 text-light d-flex py-2 pl-0 pl-sm-3 totalRow">
                  <div class="col-6 col-md-7">Total</div>
                  <div class="col-6 col-md-5 text-right"><strong>{{ currencySymbol }}{{ total_amt }}</strong></div>
                </div>
              </div>
            </div>
            <!-- <div class="card border-secondary mt-2 product_table">
                <div class="row total-wraper">
                  <input *ngIf="iscurrencyTextbox" class="form-control col-md-1 totalRow" type="text" name="currencySymbol" (click)="showCurrencyDropdown($event)" [(ngModel)]="currencySymbol" >
                  <input *ngIf="iscurrencyTextbox" class="form-control col-md-1 totalRow mob-view-none" type="text" name="currencySymbol" style="pointer-events: none">
                  <select *ngIf="iscurrencyDropdownShown" class="form-control col-md-1" name="currdrop" id="currdrop"
                    [(ngModel)]="selectedCurrency" (change)="showCurrencySelect($event)">
                    <option *ngFor="let currency of countryCurrrency; let i=index" value="{{currency.id}}">
                      {{currency.name}}</option>
                  </select>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row mx-0 mx-md-n3 justify-content-between mb-5 flex-column-reverse flex-md-row">
          <div class="col-md-7 mt-5 fig_quote">
            <p class="px-4">
              Invoice Generator lets you build professional invoices with one click using our safe and reliable invoice
              template. Whether you are a freelancer or entrepreneur, you can digitally create invoices with few clicks
              and download or print it. This allows you to keep track of all your accounting statements online while
              keeping you ahead of your priority list.
            </p>
          </div>
          <div class="col-md-5 pl-md-5 mt-0 mt-md-5 mb-4 mx-auto mr-md-0 print-btn">
            <div class="row mx-0 mb-2">
              <button type="submit" class="fig_border_yellow btn w-100 py-2"
                (click)="invoiceForm.valid ? generatePDF() : showError()">
                <img src="../assets/images/eye.svg" alt="_e" />&nbsp; Preview Invoice
              </button>
            </div>
            <div class="row mx-0">
              <div class="col-6 col-md-12 col-lg-6 p-0 pr-1 pr-md-0 pr-lg-1 mb-2">
                <button type="button" class="btn py-2 w-100 fig_bg_yellow"
                  (click)="invoiceForm.valid ? generatePDF('download') : showError()">Download Invoice</button>
              </div>
              <div class="col-6 col-md-12 col-lg-6 p-0 pl-1 pl-md-0 pl-lg-1 mb-2">
                <button type="button" class="btn py-2 w-100 fig_bg_yellow"
                (click)="invoiceForm.valid ? generatePDF('print') : showError()">Print Invoice</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <footer>
    <!-- <footer class="fixed-bottom"> -->
    <div class="text-center p-2 text-light" style="margin-top: 10px;">
      <p class="m-0">Made with love in
        <a href="https://www.codolin.com/" target="_blank">
          <span class="cod-text-color"> Codolin Technologies Pvt. Ltd.</span></a>
      </p>
    </div>
  </footer>
</div>